import React from "react";

const Home = () => {
  return (
    <div>
      <img src="/images/cat.jpg" style={{ width: "70vw" }} />
      <div>
        You can find me at{" "}
        <a href="https://mas.to/@arcticfly">@arcticfly@mas.to</a>
      </div>
    </div>
  );
};

export default Home;
