import { HStack } from "native-base";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Essays from "./essays/Essays";
import Home from "./pages/home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/essays/:essayId?",
    element: <Essays />,
  },
]);

const Navigation = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          backgroundColor: "#ff5600",
          padding: "4vw",
          display: "flex",
          flexDirection: "column",
          flexShrink: 1,
        }}
      >
        <LinkItem title="Home" href="/" />
        <LinkItem title="Essays" href="/essays" />
      </div>
      <div style={{ flexGrow: 1, textAlign: "left", padding: "4vw" }}>
        <div style={{ fontSize: 64, fontWeight: 900, color: "#fff0f0" }}>
          DAVID CORBITT
        </div>
        <RouterProvider router={router} />
      </div>
    </div>
  );
};

const LinkItem = ({ title, href }: { href: string; title: string }) => {
  return (
    <a href={href} style={{ paddingTop: 8 }}>
      {title}
    </a>
  );
};

export default Navigation;
