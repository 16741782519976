import { key } from "localforage";
import React, { useMemo } from "react";
import OwningACornerOfTheInternet from "./OwningACornerOfTheInternet";
import WhyHumansAreBetterThanOctopi from "./WhyHumansAreBetterThanOctopi";

const essays: { [essayId: string]: { title: string; element: JSX.Element } } = {
  "owning-a-corner-of-the-internet": {
    title: `Owning a Corner of the Internet`,
    element: <OwningACornerOfTheInternet />,
  },
  "why-humans-are-better-than-octopi": {
    title: `Why Humans are Better than Octopi`,
    element: <WhyHumansAreBetterThanOctopi />,
  },
};

const Essays = () => {
  const essayId = window.location.pathname.split("/")[2];

  const [title, element] = useMemo(() => {
    if (!essays[essayId]) {
      return ["Essays", <EmptyComponent />];
    }
    return [essays[essayId].title, essays[essayId].element];
  }, [essayId]);
  return (
    <div style={{ padding: 16 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <h1 style={{ textAlign: "center" }}>{title}</h1>
        {element}
      </div>
    </div>
  );
};

const EmptyComponent = () => {
  return (
    <ul>
      {Object.keys(essays).map((key) => (
        <li>
          <a href={`/essays/${key}`}>{essays[key].title}</a>
        </li>
      ))}
    </ul>
  );
};

export default Essays;
