const WhyHumansAreBetterThanOctopi = () => {
  return (
    <div>
      <p>
        A couple of years ago I became interested in octopi and their unusual
        level of intelligence. Specifically, I was interested in what they were
        able to do with their intelligence, and in the possibility of octopi
        eventually forming a powerful force in their habitat, similarly to how
        humans have been able to dominate life on the surface of the earth. The
        more I learned about the unusual skills and abilities that octopi
        possess, the more interested I became. For instance, did you know that
        octopi can learn new skills just by observing other octopi solving a
        difficult problem? An octopus has enough empathy and self-awareness to
        put themselves in the shoes (metaphorically speaking) of another
        creature, and learn from that creature's struggles. When we also
        consider the ability of octopi to solve problems quickly, wield tools
        with their many suckered tentacles, and survive in diverse habitats, it
        seems they should have achieved some sort of dominance over their
        environment, especially in light of the relatively primitive and slowly
        evolving life forms with which they compete.
      </p>
      <p>
        But octopi have not mastered their environment. In fact, according to{" "}
        <a href="https://www.nationalgeographic.com/environment/article/marine-food-chain#:~:text=Level%20Three%3A%20Carnivores&text=This%20level%20of%20the%20food,invertebrates%20that%20live%20near%20shore).">
          National Geographic
        </a>
        , Octopi aren't even on the top level of their food chain. They are prey
        to eels, fish, seals, sea otters, and many birds. Unlike humans, octopi
        have not managed to subdue their environment to a sufficient extent to
        live without fear of attack from other less intelligent species. And I
        believe the reason for their lack of success, despite all of their
        natural ability, is that they are unable to quickly learn from one
        another in practice.
      </p>
      <p>
        The reproductive cycle of an octopus is quite strange, and completely
        different from that of a human. While human parents are expected to live
        the majority of their lives <i>after</i> producing their first child,
        octopi give their lives to their children. After mating, male octopi die
        off within a few months, while female octopi (after a gestation period
        of around 10 months) die shortly after giving birth (learn more in this{" "}
        <a href="https://www.vedantu.com/biology/octopus-life-cycle">
          interesting article
        </a>
        ). This means that all the octopus babies essentially start over from
        scratch. They have 3-5 years to learn everything they can about the
        world, and how to survive and thrive within it, until they too will mate
        and all their knowledge and skills will die with them. Great octopus
        geniuses have no doubt risen, lived glorious and successful lives, and
        died without leaving any lasting impact on the larger octopus
        population.
      </p>
      <p>
        I'm aware that octopi and humans differ in many respects (and I am aware
        that my understanding is too shallow to know what most of these respects
        are) but I am convinced that the difference in learning patterns between
        humans and octopi remains the largest differentiator in our relative
        success at mastering our environments and building successful
        civilizations. Humans can quickly learn not only from their own parents
        (which is where we learn most things when we are very young) but from
        other adults in our environments as well.
      </p>
      <p>
        This line of pondering was sparked by my reaction to my own obvious
        parodying of Paul Graham's excellent website (if you haven't checked out{" "}
        <a href="http://paulgraham.com/index.html">
          http://paulgraham.com/index.html
        </a>
        , you really should). The truth is, I don't know instinctively how to make a really great and useful website, so the best I can do is copy what I know works and go from there. You shouldn't feel bad about copying things you see work around you either. To parody the old saying, "If it ain't broke, copy it and determine if there is anything broken about it before you fix it."
      </p>
    </div>
  );
};

export default WhyHumansAreBetterThanOctopi;
