const OwningACornerOfTheInternet = () => {
  return (
    <div>
      <p>
        I bought this domain and created a basic website because I want to own
        some small corner of the internet for myself. I understand that my
        current setup is dependent on many layers of infrastructure run by
        private companies with very little obligation to me in particular, but
        that won't stop me from seeking ownership to the greatest extent
        possible (without exercising an unreasonable amount of effort).
      </p>
      <p>
        I don't have very much useful information to add to this website yet,
        but the act of attempting to write something useful has at least
        demonstrated my own lack of knowledge to me, which spurs me to learn
        something worth sharing.
      </p>
      <p>
        There's something fresh about creating a new website from scratch, without any preconceptions about user-friendly design, or any need to meet a business goal. Play is an ideal mechanism for learning new skills. However, I am not playing around with web technology in order to learn new skills, as that would ruin the fun of playing.  I am not choosing a set of technologies that will be useful in future projects.  In fact, I am deliberately ignoring design rules and declining technologies that will make this site look beautiful and modern.  I am just messing around with an old set of technologies to create a useless site that few people besides myself will ever find.  How refreshing!
      </p>
    </div>
  );
};

export default OwningACornerOfTheInternet;
