// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA7CqNXzx28scmzN9O0o7CZNDjJrxZW9GE",
  authDomain: "david-corbitt.firebaseapp.com",
  projectId: "david-corbitt",
  storageBucket: "david-corbitt.appspot.com",
  messagingSenderId: "452393219662",
  appId: "1:452393219662:web:67e544bf6aa24aa8ff751f",
  measurementId: "G-77X1CVTTM9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);